:root {
    --dark-5: rgb(1, 1, 1);
    --dark-4: rgba(11, 23, 40, 100);
    --dark-3: rgba(22, 45, 80, 100);
    --dark-2: rgba(33, 68, 120, 100);
    --dark-1: rgba(44, 90, 160, 100);
    --light-0: rgba(55, 113, 200, 100);
    --light-1: rgba(95, 141, 211, 100);
    --light-2: rgba(135, 170, 222, 100);
    --light-3: rgba(175, 198, 233, 100);
    --light-4: rgba(215, 227, 244, 100);

    --font-0: 'Montserrat', sans-serif;
    --font-1: 'Karla', sans-serif;
    --font-2: monospace;
}

.app { font-family: var(--font-1); }
.star {
    background-image: url("../public/images/Star Seamless Pattern - Blur 1.png");
    background-repeat: repeat;
    background-size: 200px;
}
.star-border
{
    border: solid;
    border-width: 3px;
    border-radius: 5px;
    border-color: var(--dark-2);
}
h1, h2, h3, h4, h5, h6 
{
     font-family: var(--font-0); 
     text-shadow: var(--dark-5) 3px 3px 3px;
}
.ft-mono { font-family: var(--font-2); }
.ft-secondary {
    font-family: var(--font-1);
    font-weight: 200;
    font-style: italic;
    text-shadow: none;
}

.ft-primary {
    font-family: var(--font-0);
    font-weight: 900;
    text-shadow: var(--dark-5) 4px 4px 4px;
}

.o-hiden { overflow: hidden; }
.l-style-none { list-style: none; }
.t-dec-none { text-decoration: none; }
.img-center { display: inline-block; }
.text-justify { text-align: justify; }
.property-list b { color: var(--light-0); }


.c-dark-4 { color: var(--dark-4); }
.c-dark-3 { color: var(--dark-3); }
.c-dark-2 { color: var(--dark-2); }
.c-dark-1 { color: var(--dark-1); }
.c-light-0 { color: var(--light-0); }
.c-light-1 { color: var(--light-1); }
.c-light-2 { color: var(--light-2); }
.c-light-3 { color: var(--light-3); }
.c-light-4 { color: var(--light-4); }

.c-dark-4-h:hover { color: var(--dark-4); }
.c-dark-3-h:hover { color: var(--dark-3); }
.c-dark-2-h:hover { color: var(--dark-2); }
.c-dark-1-h:hover { color: var(--dark-1); }
.c-light-0-h:hover { color: var(--light-0); }
.c-light-1-h:hover { color: var(--light-1); }
.c-light-2-h:hover { color: var(--light-2); }
.c-light-3-h:hover { color: var(--light-3); }
.c-light-4-h:hover { color: var(--light-4); }

.bg-dark-5 { background-color: var(--dark-5); }
.bg-dark-4 { background-color: var(--dark-4); }
.bg-dark-3 { background-color: var(--dark-3); }
.bg-dark-2 { background-color: var(--dark-2); }
.bg-dark-1 { background-color: var(--dark-1); }
.bg-light-0 { background-color: var(--light-0); }
.bg-light-1 { background-color: var(--light-1); }
.bg-light-2 { background-color: var(--light-2); }
.bg-light-3 { background-color: var(--light-3); }
.bg-light-4 { background-color: var(--light-4); }

.bg-dark-40 { background: linear-gradient(0deg, var(--dark-4) 0%, var(--dark-3) 15%, var(--dark-4) 25%); }
.bg-dark-43 { background: linear-gradient(0deg, var(--dark-4) 0%, var(--dark-3) 15%); }
.bg-dark-34 { background: linear-gradient(0deg, var(--dark-3) 85%, var(--dark-4) 100%); }
.bg-dark-20 { background: linear-gradient(0deg, var(--dark-5) 0%, var(--dark-3) 5%, var(--dark-3) 95%, var(--dark-5) 100%); }

.fade-in { opacity: 0; transition: all 0.5s ease-in-out; }
.fade-in:hover { opacity: 100; transition: all 0.5s ease-in-out; }
.fade-out { opacity: 100; transition: all 0.5s ease-in-out; }
.fade-out:hover { opacity: 0; transition: all 0.5s ease-in-out; }


.bg-dark-4-h:hover { background-color: var(--dark-4); }
.bg-dark-3-h:hover { background-color: var(--dark-3); }
.bg-dark-2-h:hover { background-color: var(--dark-2); }
.bg-dark-1-h:hover { background-color: var(--dark-1); }
.bg-light-0-h:hover { background-color: var(--light-0); }
.bg-light-1-h:hover { background-color: var(--light-1); }
.bg-light-2-h:hover { background-color: var(--light-2); }
.bg-light-3-h:hover { background-color: var(--light-3); }
.bg-light-4-h:hover { background-color: var(--light-4); }

.br-dark-4 { border-color: var(--dark-4); }
.br-dark-3 { border-color: var(--dark-3); }
.br-dark-2 { border-color: var(--dark-2); }
.br-dark-1 { border-color: var(--dark-1); }
.br-light-0 { border-color: var(--light-0); }
.br-light-1 { border-color: var(--light-1); }
.br-light-2 { border-color: var(--light-2); }
.br-light-3 { border-color: var(--light-3); }
.br-light-4 { border-color: var(--light-4); }

.br-dark-4-h:hover { border-color: var(--dark-4); }
.br-dark-3-h:hover { border-color: var(--dark-3); }
.br-dark-2-h:hover { border-color: var(--dark-2); }
.br-dark-1-h:hover { border-color: var(--dark-1); }
.br-light-0-h:hover { border-color: var(--light-0); }
.br-light-1-h:hover { border-color: var(--light-1); }
.br-light-2-h:hover { border-color: var(--light-2); }
.br-light-3-h:hover { border-color: var(--light-3); }
.br-light-4-h:hover { border-color: var(--light-4); }

.w-0 { width: 0%; }
.w-5 { width: 5%; }
.w-10 { width: 10%; }
.w-15 { width: 15%; }
.w-20 { width: 20%; }
.w-25 { width: 25%; }
.w-30 { width: 30%; }
.w-35 { width: 35%; }
.w-40 { width: 40%; }
.w-45 { width: 45%; }
.w-50 { width: 50%; }
.w-55 { width: 55%; }
.w-60 { width: 60%; }
.w-65 { width: 65%; }
.w-70 { width: 70%; }
.w-75 { width: 75%; }
.w-80 { width: 80%; }
.w-85 { width: 85%; }
.w-90 { width: 90%; }
.w-95 { width: 95%; }
.w-100 { width: 100%; }

@media screen and (max-width: 576px) {
    .w-xs-0 { width: 0% !important; }
    .w-xs-5 { width: 5% !important; }
    .w-xs-10 { width: 10% !important; }
    .w-xs-15 { width: 15% !important; }
    .w-xs-20 { width: 20% !important; }
    .w-xs-25 { width: 25% !important; }
    .w-xs-30 { width: 30% !important; }
    .w-xs-35 { width: 35% !important; }
    .w-xs-40 { width: 40% !important; }
    .w-xs-45 { width: 45% !important; }
    .w-xs-50 { width: 50% !important; }
    .w-xs-55 { width: 55% !important; }
    .w-xs-60 { width: 60% !important; }
    .w-xs-65 { width: 65% !important; }
    .w-xs-70 { width: 70% !important; }
    .w-xs-75 { width: 75% !important; }
    .w-xs-80 { width: 80% !important; }
    .w-xs-85 { width: 85% !important; }
    .w-xs-90 { width: 90% !important; }
    .w-xs-95 { width: 95% !important; }
    .w-xs-100 { width: 100% !important; }
}
@media screen and (max-width: 768px) {
    .w-sm-0 { width: 0% !important; }
    .w-sm-5 { width: 5% !important; }
    .w-sm-10 { width: 10% !important; }
    .w-sm-15 { width: 15% !important; }
    .w-sm-20 { width: 20% !important; }
    .w-sm-25 { width: 25% !important; }
    .w-sm-30 { width: 30% !important; }
    .w-sm-35 { width: 35% !important; }
    .w-sm-40 { width: 40% !important; }
    .w-sm-45 { width: 45% !important; }
    .w-sm-50 { width: 50% !important; }
    .w-sm-55 { width: 55% !important; }
    .w-sm-60 { width: 60% !important; }
    .w-sm-65 { width: 65% !important; }
    .w-sm-70 { width: 70% !important; } 
    .w-sm-75 { width: 75% !important; } 
    .w-sm-80 { width: 80% !important; } 
    .w-sm-85 { width: 85% !important; }
    .w-sm-90 { width: 90% !important; }
    .w-sm-95 { width: 95% !important; }
    .w-sm-100 { width: 100% !important; }
}
@media screen and (max-width: 992px) {
    .w-md-0 { width: 0% !important; }
    .w-md-5 { width: 5% !important; }
    .w-md-10 { width: 10% !important; }
    .w-md-15 { width: 15% !important; }
    .w-md-20 { width: 20% !important; }
    .w-md-25 { width: 25% !important; }
    .w-md-30 { width: 30% !important; }
    .w-md-35 { width: 35% !important; }
    .w-md-40 { width: 40% !important; }
    .w-md-45 { width: 45% !important; }
    .w-md-50 { width: 50% !important; }
    .w-md-55 { width: 55% !important; }
    .w-md-60 { width: 60% !important; }
    .w-md-65 { width: 65% !important; }
    .w-md-70 { width: 70% !important; } 
    .w-md-75 { width: 75% !important; }
    .w-md-80 { width: 80% !important; }
    .w-md-85 { width: 85% !important; }
    .w-md-90 { width: 90% !important; }
    .w-md-95 { width: 95% !important; }
    .w-md-100 { width: 100% !important; }
}
@media screen and (max-width: 1200px) {
    .w-lg-0 { width: 0% !important; }
    .w-lg-5 { width: 5% !important; }
    .w-lg-10 { width: 10% !important; }
    .w-lg-15 { width: 15% !important; }
    .w-lg-20 { width: 20% !important; }
    .w-lg-25 { width: 25% !important; }
    .w-lg-30 { width: 30% !important; }
    .w-lg-35 { width: 35% !important; }
    .w-lg-40 { width: 40% !important; }
    .w-lg-45 { width: 45% !important; } 
    .w-lg-50 { width: 50% !important; }
    .w-lg-55 { width: 55% !important; }
    .w-lg-60 { width: 60% !important; }
    .w-lg-65 { width: 65% !important; }
    .w-lg-70 { width: 70% !important; }
    .w-lg-75 { width: 75% !important; }
    .w-lg-80 { width: 80% !important; }
    .w-lg-85 { width: 85% !important; }
    .w-lg-90 { width: 90% !important; }
    .w-lg-95 { width: 95% !important; }
    .w-lg-100 { width: 100% !important; }
}
@media screen and (max-width: 1400px) {
    .w-xl-0 { width: 0% !important; }
    .w-xl-5 { width: 5% !important; }
    .w-xl-10 { width: 10% !important; }
    .w-xl-15 { width: 15% !important; }
    .w-xl-20 { width: 20% !important; }
    .w-xl-25 { width: 25% !important; }
    .w-xl-30 { width: 30% !important; }
    .w-xl-35 { width: 35% !important; }
    .w-xl-40 { width: 40% !important; }
    .w-xl-45 { width: 45% !important; } 
    .w-xl-50 { width: 50% !important; }
    .w-xl-55 { width: 55% !important; }
    .w-xl-60 { width: 60% !important; }
    .w-xl-65 { width: 65% !important; }
    .w-xl-70 { width: 70% !important; } 
    .w-xl-75 { width: 75% !important; }
    .w-xl-80 { width: 80% !important; }
    .w-xl-85 { width: 85% !important; }
    .w-xl-90 { width: 90% !important; }
    .w-xl-95 { width: 95% !important; }
    .w-xl-100 { width: 100% !important; }
}
@media screen and (min-width: 1400px) {
    .w-xxl-0 { width: 0% !important; }
    .w-xxl-5 { width: 5% !important; }
    .w-xxl-10 { width: 10% !important; }
    .w-xxl-15 { width: 15% !important; }
    .w-xxl-20 { width: 20% !important; }
    .w-xxl-25 { width: 25% !important; }
    .w-xxl-30 { width: 30% !important; }
    .w-xxl-35 { width: 35% !important; }
    .w-xxl-40 { width: 40% !important; }
    .w-xxl-45 { width: 45% !important; }
    .w-xxl-50 { width: 50% !important; }
    .w-xxl-55 { width: 55% !important; }
    .w-xxl-60 { width: 60% !important; }
    .w-xxl-65 { width: 65% !important; }
    .w-xxl-70 { width: 70% !important; }
    .w-xxl-75 { width: 75% !important; }
    .w-xxl-80 { width: 80% !important; }
    .w-xxl-85 { width: 85% !important; }
    .w-xxl-90 { width: 90% !important; }
    .w-xxl-95 { width: 95% !important; }
    .w-xxl-100 { width: 100% !important; }
}

.h-1 { height: 1px; }
.h-2 { height: 2px; }
.h-3 { height: 3px; }
.h-4 { height: 4px; }
.h-5 { height: 5px; }
.h-6 { height: 6px; }
.h-7 { height: 7px; }
.h-8 { height: 8px; }
.h-9 { height: 9px; }
.hg-10 { height: 10px; }
.hg-20 { height: 20px; }
.hg-30 { height: 30px; }
.hg-40 { height: 40px; }
.hg-50 { height: 50px; }
.hg-60 { height: 60px; }
.hg-70 { height: 70px; }
.hg-80 { height: 80px; }
.hg-90 { height: 90px; }
.hg-100 { height: 100px; }

.br-w-0 { border-width: 0px; }
.br-w-1 { border-width: 1px; }
.br-w-2 { border-width: 2px; }
.br-w-3 { border-width: 3px; }
.br-w-4 { border-width: 4px; }
.br-w-5 { border-width: 5px; }
.br-w-6 { border-width: 6px; }
.br-w-7 { border-width: 7px; }
.br-w-8 { border-width: 8px; }
.br-w-9 { border-width: 9px; }
.br-w-10 { border-width: 10px; }

.br-r-0 { border-radius: 0px; }
.br-r-1 { border-radius: 1px; }
.br-r-2 { border-radius: 2px; }
.br-r-3 { border-radius: 3px; }
.br-r-4 { border-radius: 4px; }
.br-r-5 { border-radius: 5px; }
.br-r-6 { border-radius: 6px; }
.br-r-7 { border-radius: 7px; }
.br-r-8 { border-radius: 8px; }
.br-r-9 { border-radius: 9px; }
.br-r-10 { border-radius: 10px; }

.br-dashed { border-style: dashed; }
.br-dotted { border-style: dotted; }
.br-double { border-style: double; }
.br-groove { border-style: groove; }
.br-hidden { border-style: hidden; }
.br-inherit { border-style: inherit; }
.br-initial { border-style: initial; }
.br-inset { border-style: inset; }
.br-none { border-style: none; }
.br-outset { border-style: outset; }
.br-ridge { border-style: ridge; }
.br-solid { border-style: solid; }
.br-unset { border-style: unset; }

.ar-100 { padding-top: 3%; padding-bottom: 0%; }
.pos-abs { position: absolute; }

.pos-top-0 { top: 0%; }
.pos-top-5 { top: 5%; }
.pos-top-10 { top: 10%; }
.pos-top-15 { top: 15%; }
.pos-top-20 { top: 20%; }
.pos-top-25 { top: 25%; }
.pos-top-30 { top: 30%; }
.pos-top-35 { top: 35%; }
.pos-top-40 { top: 40%; }
.pos-top-45 { top: 45%; }
.pos-top-50 { top: 50%; }
.pos-top-55 { top: 55%; }
.pos-top-60 { top: 60%; }
.pos-top-65 { top: 65%; }
.pos-top-70 { top: 70%; }
.pos-top-75 { top: 75%; }
.pos-top-80 { top: 80%; }
.pos-top-85 { top: 85%; }
.pos-top-90 { top: 90%; }
.pos-top-95 { top: 95%; }
.pos-top-100 { top: 100%; }

.pos-left-0 { left: 0%; }
.pos-left-5 { left: 5%; }
.pos-left-10 { left: 10%; }
.pos-left-15 { left: 15%; }
.pos-left-20 { left: 20%; }
.pos-left-25 { left: 25%; }
.pos-left-30 { left: 30%; }
.pos-left-35 { left: 35%; }
.pos-left-40 { left: 40%; }
.pos-left-45 { left: 45%; }
.pos-left-50 { left: 50%; }
.pos-left-55 { left: 55%; }
.pos-left-60 { left: 60%; }
.pos-left-65 { left: 65%; }
.pos-left-70 { left: 70%; }
.pos-left-75 { left: 75%; }
.pos-left-80 { left: 80%; }
.pos-left-85 { left: 85%; }
.pos-left-90 { left: 90%; }
.pos-left-95 { left: 95%; }
.pos-left-100 { left: 100%; }

.ts-i { font-style: italic; }

.fs-sxxx { font-size: xx-small; }
.fs-sxx { font-size: x-small; }
.fs-sx { font-size: smaller; }
.fs-sm { font-size: small; }
.fs-md { font-size: medium; }
.fs-lg { font-size: large; }
.fs-lx { font-size: larger; }
.fs-lxx { font-size: x-large; }
.fs-lxxx { font-size: xx-large; }


@media screen and (max-width: 576px) {
    .fs-xs-sxxx { font-size: xx-small; }
    .fs-xs-sxx { font-size: x-small; } 
    .fs-xs-sx { font-size: smaller; }
    .fs-xs-sm { font-size: small; }
    .fs-xs-md { font-size: medium; }
    .fs-xs-lg { font-size: large; }
    .fs-xs-lx { font-size: larger; }
    .fs-xs-lxx { font-size: x-large; } 
    .fs-xs-lxxx { font-size: xx-large; }
}

@media screen and (max-width: 768px) {
    .fs-sm-sxxx { font-size: xx-small; }
    .fs-sm-sxx { font-size: x-small; }
    .fs-sm-sx { font-size: smaller; }
    .fs-sm-sm { font-size: small; }
    .fs-sm-md { font-size: medium; }
    .fs-sm-lg { font-size: large; }
    .fs-sm-lx { font-size: larger;}
    .fs-sm-lxx { font-size: x-large; }
    .fs-sm-lxxx { font-size: xx-large; }
}

@media screen and (max-width: 992px) {
    .fs-md-sxxx { font-size: xx-small; }
    .fs-md-sxx { font-size: x-small; }
    .fs-md-sx { font-size: smaller; }
    .fs-md-sm { font-size: small; }
    .fs-md-md { font-size: medium; }
    .fs-md-lg { font-size: large; }
    .fs-md-lx { font-size: larger; }
    .fs-md-lxx { font-size: x-large; }        
    .fs-md-lxxx { font-size: xx-large; }
}

@media screen and (max-width: 1200px) {
    .fs-lg-sxxx { font-size: xx-small; }
    .fs-lg-sxx { font-size: x-small; }
    .fs-lg-sx { font-size: smaller; }
    .fs-lg-sm { font-size: small; }
    .fs-lg-md { font-size: medium; }
    .fs-lg-lg { font-size: large; }
    .fs-lg-lx { font-size: larger; }
    .fs-lg-lxx { font-size: x-large; }
    .fs-lg-lxxx { font-size: xx-large; }
}

@media screen and (max-width: 1400px) {
    .fs-lx-sxxx { font-size: xx-small; }
    .fs-lx-sxx { font-size: x-small; }
    .fs-lx-sx { font-size: smaller; }
    .fs-lx-sm { font-size: small; }
    .fs-lx-md { font-size: medium; }
    .fs-lx-lg { font-size: large; }
    .fs-lx-lx { font-size: larger; }
    .fs-lx-lxx { font-size: x-large; }
    .fs-lx-lxxx { font-size: xx-large; }
}

@media screen and (min-width: 1400px) {
    .fs-lxx-sxxx { font-size: xx-small; }
    .fs-lxx-sxx { font-size: x-small; }
    .fs-lxx-sx { font-size: smaller; }
    .fs-lxx-sm { font-size: small; }
    .fs-lxx-md { font-size: medium; }
    .fs-lxx-lg { font-size: large; }
    .fs-lxx-lx { font-size: larger; }
    .fs-lxx-lxx { font-size: x-large; }
    .fs-lxx-lxxx { font-size: xx-large; }
}