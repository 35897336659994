.tree {
    color: rgba(135, 170, 222, 100);
    background-color: rgba(11, 23, 40, 100);
    margin: 0;
    padding: 0;

    border: solid;
    border-width: 3px;
    border-radius: 5px;
    border-color: rgba(33, 68, 120, 100);
}

.tree a {
    text-decoration: none;
}

.tree li {
    list-style-type: none;
    user-select: none;
    font-size: large;
}

.tree li:hover {
    color: rgba(215, 227, 244, 100);
    background-color: rgba(44, 90, 160, 100);
}

.tree ul {
    margin: 0;
    padding: 0;

    border: solid;
    border-radius: 5px;
    border-width: 5px;
    border-color: rgba(22, 45, 80, 100);
}

.tree-item {
    margin: 0;
    padding: 0px 10px;
    text-decoration: none;
    color: rgba(135, 170, 222, 100);
}

.tree-nested-label-close {
    transition: all 0.2s ease-in-out;
}

.tree-nested-open {
    background-color: rgba(22, 45, 80, 100);

    border: solid;
    border-radius: 5px;
    border-width: 5px;
    border-color: rgba(11, 23, 40, 100);

    transition: all 0.2s ease-in-out;
}

.tree-nested-label-open {
    background-color: rgba(11, 23, 40, 100);

    border: solid;
    border-radius: 5px;
    border-width: 5px;
    border-color: rgba(22, 45, 80, 100);

    transition: all 0.2s ease-in-out;
}

.tree-nested-label-close {
    background-color: rgba(22, 45, 80, 100);

    transition: all 0.2s ease-in-out;
}