.cardBlock {
    border: solid;
    border-radius: 5px;
    border-width: 3px;
    border-color: rgba(22, 45, 80, 100);
}

.cardBlock:hover {
    box-shadow: 0px 0px 5px 5px rgba(22, 45, 80, 100) !important;
    transition: all 0.1s linear;
}

.cardBlock-img { transition: all 0.2s ease-in-out; }
.cardBlock-img:hover { transform: scale(.9); }

.cardBlock-btn {
    text-decoration: none;

    color: rgba(175, 198, 233, 100);
    background-color: rgba(11, 23, 40, 100);
    border-color: rgba(175, 198, 233, 100);

    padding: 5px;

    border: solid;
    border-radius: 5px;
    border-width: 1px;
}

.cardBlock-btn:hover {
    color: rgba(11, 23, 40, 100);
    background-color: rgba(33, 68, 120, 100);
    border-color: rgba(22, 45, 80, 100);
}