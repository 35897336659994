.code {
    background-color: black;
    color: grey;
    padding: .5rem;

    border: solid;
    border-width: 3px;
    border-radius: 5px;
    border-color: rgba(33, 68, 120, 100);

    font-size: medium;
}

@media screen and (max-width: 992px) {
    .code {
        font-size: small;
    }
}

.code i {
    color: green;
    float: right;
}